import styles from "./Distance.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import Distance from "./Distance";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import PlusFillIcon from "../../Assests/Images/PlusfillIcon.svg";
import PbDistance from "./PbDistance";

function DistancePage() {
  const { t } = useTranslation("");
  const [distanceTabShow, setDistanceTabShow] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [showPbDistance, setShowPbDistance] = useState(false);
  const [distanceId, setDistanceId] = useState("");
  const [distanceUnit, setDistanceUnit] = useState("KM");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  //=== manage add/edit form show hide based on active tab===
  // const toggleAddDistance = () => {
  //   if (distanceTabShow === 1) {
  //     setShowAdd(!showAdd);
  //     setShowPbDistance(false);
  //   } else if (distanceTabShow === 2) {
  //     setShowPbDistance(!showPbDistance);
  //     setShowAdd(false);
  //   }
  //   setDistanceId("");
  //   setHours(0);
  //   setMinutes(0);
  //   setDistanceUnit("KM");
  // };

  const toggleAddDistance = () => {
    if (distanceTabShow === 1) {
      // Toggle add form for "Distances" tab
      setShowAdd(!showAdd);
      if (showPbDistance) setShowPbDistance(false); // Close PB form if open
    } else if (distanceTabShow === 2) {
      // Toggle add form for "PB Distances" tab
      setShowPbDistance(!showPbDistance);
      if (showAdd) setShowAdd(false); // Close Add form if open
    }

    // Only reset form fields when opening the form, not when closing it
    if (!showAdd && !showPbDistance) {
      setDistanceId("");
      setHours(0);
      setMinutes(0);
      setDistanceUnit("KM");
    }
  };

  return (
    <div className={styles.distanceWrapper}>
      <p className={styles.heading}>
        {distanceTabShow === 1
          ? t("DISTANCES")
          : distanceTabShow === 2
          ? t("DISTANCE > PB DISTANCES")
          : t("DISTANCE > ADD NEW")}
      </p>
      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => {
              setDistanceTabShow(1);
              setShowAdd(false);
              setShowPbDistance(false);
            }}
            variant={
              distanceTabShow === 1 && !showAdd ? "primary" : "secondary"
            }
          >
            {t("DISTANCES")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => {
              setDistanceTabShow(2);
              setShowAdd(false);
              setShowPbDistance(false);
            }}
            variant={
              distanceTabShow === 2 && !showPbDistance ? "primary" : "secondary"
            }
          >
            {t("PB_DISTANCE")}
          </CustomTabButton>

          <CustomImgButton
            onClick={toggleAddDistance}
            src={showAdd || showPbDistance ? PlusFillIcon : PlusIcon}
            alt="PlusIcon"
          />
        </div>

        {distanceTabShow === 1 ? (
          <Distance
            showAdd={showAdd}
            setShowAdd={setShowAdd}
            distanceId={distanceId}
            setDistanceId={setDistanceId}
            distanceUnit={distanceUnit}
            setDistanceUnit={setDistanceUnit}
            hours={hours}
            setHours={setHours}
            minutes={minutes}
            setMinutes={setMinutes}
          />
        ) : distanceTabShow === 2 ? (
          <PbDistance
            showPbDistance={showPbDistance}
            setShowPbDistance={setShowPbDistance}
            distanceId={distanceId}
            setDistanceId={setDistanceId}
            distanceUnit={distanceUnit}
            setDistanceUnit={setDistanceUnit}
            hours={hours}
            setHours={setHours}
            minutes={minutes}
            setMinutes={setMinutes}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default DistancePage;
