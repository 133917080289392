import { LuLayoutDashboard } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { RiCalendarCheckLine } from "react-icons/ri";
import { CiRoute } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { FaHeartPulse } from "react-icons/fa6";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import { GiNotebook } from "react-icons/gi";
import { MdOutlinePrivacyTip } from "react-icons/md";

export const SidebarJSON = [
  {
    title: "Dashboard",
    navigateTo: "/dashboard",
    icon: <LuLayoutDashboard size={25} className="mx-3" />,
  },
  {
    title: "Brands",
    navigateTo: "/brands",
    icon: <RiVerifiedBadgeLine size={25} className="mx-3" />,
  },
  {
    title: "Members",
    navigateTo: "/members",
    icon: <FaRegCircleUser size={25} className="mx-3" />,
  },
  {
    title: "Events",
    navigateTo: "/events",
    icon: <RiCalendarCheckLine size={25} className="mx-3" />,
  },

  {
    title: "Distances",
    navigateTo: "/distance",
    icon: <CiRoute size={25} className="mx-3" fontWeight={700} />,
  },

  {
    title: "Workout Types",
    navigateTo: "/workout-page",
    icon: <FaHeartPulse size={25} className="mx-3" />,
  },
  {
    title: "Terms & Condition",
    navigateTo: "terms-condition",
    icon: <GiNotebook size={25} className="mx-3" />,
  },
  {
    title: "Privacy Policy",
    navigateTo: "privacy-policy",
    icon: <MdOutlinePrivacyTip size={25} className="mx-3" />,
  },
  {
    title: "Contact Us",
    navigateTo: "contact-us",
    icon: <MdOutlinePrivacyTip size={25} className="mx-3" />,
  },
];
