import React, { useEffect, useRef, useState } from "react";
import styles from "./LogBook.module.css";
import demoprofileImg from "../../Assests/Images/ProfileDemo.svg";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditLogbook from "./EditLogbook";
import CalenderComp from "./CalenderComp";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONSTANT } from "../../Utils/Constant";
import Loader from "../../Utils/Loader/Loader";
import DOMPurify from "dompurify";
import DeleteLogbook from "./DeleteLogbook";
import { MONTH_CODE } from "../../Utils/MonthCode";
import SublyApi from "../../Helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../store/slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import {
  capitalizeFirstLetter,
  isContentEmpty,
} from "../../Utils/CommonFunction";

function LogBook({ userName, profileImg, member_id, memberData }) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousRef = useRef();
  const nextRef = useRef();
  const [addLogbook, setAddLogbook] = useState(false);
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [logbookList, setLogbookList] = useState();
  const [logBookId, setLogBookId] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const handleShowDate = () => setShowDate(true);
  const handleCloseDate = () => setShowDate(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const containerRef = useRef(null);

  //===== function for manage total count============
  useEffect(() => {
    if (logbookList) {
      const count = logbookList.filter((item) => item.memberWorkoutId).length;
      setTotalCount(count);
    }
  }, [logbookList]);

  const currentDate = new Date();

  const daysOfWeek = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];
  const monthsOfYear = [
    t("JANUARY"),
    t("FEB"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEP"),
    t("OCT"),
    t("NOV"),
    t("DEC"),
  ];

  useEffect(() => {
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const handleEdit = (workout) => {
    setSelectedWorkout(workout);
    setAddLogbook(true);
  };

  useEffect(() => {
    var daySelected = day;

    if (day.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;

    setSelectedDate(rightFormatOfDate);

    if (selectedDate) {
      handleGetLogbook(rightFormatOfDate);
    }
  }, [day, year, month, weekday]);

  const callgoToPreviousMonth = () => {
    setAddLogbook(false);
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    setAddLogbook(false);
    nextRef.current.handleCallNext();
  };

  //======== logbook list api=============
  const handleGetLogbook = (date) => {
    if (date) {
      const response = SublyApi.getWorkoutLogbook(userToken, member_id, date);
      setIsLoading(true);
      response.then((response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODES.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setLogbookList(response?.data?.workoutList);
        }
      });
    }
  };

  useEffect(() => {
    handleGetLogbook(selectedDate);
  }, []);

  const handleDeleteLogbook = () => {
    const response = SublyApi.DeleteLogbook(userToken, member_id, logBookId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODES.SUCCESS) {
          Toster(t("LOGBOOK_DELETED"), "success");
          handleGetLogbook(selectedDate);
          setShowDelete(false);
        } else if (response.data.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
          setShowDelete(false);
        } else {
          Toster(t(ResponseCode), "error");
          setShowDelete(false);
        }
      }
    });
  };

  //2️⃣ watch for when add button click
  useEffect(() => {
    if (addLogbook) {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [addLogbook]);

  return (
    <div ref={containerRef}>
      {isLoading && <Loader />}
      <div className={styles.userName}>
        <img
          src={
            profileImg
              ? profileImg
              : memberData?.profilePicture
              ? memberData?.profilePicture
              : demoprofileImg
          }
          alt="profileImg"
        ></img>
        <p>
          {userName
            ? userName
            : `${capitalizeFirstLetter(memberData?.firstName)} ${
                memberData?.lastName ? memberData?.lastName : ""
              }`}
        </p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
            <FaLessThan size="15" color="#000000" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div className={styles.iconHolder} onClick={callgoToNextMonth}>
            <FaGreaterThan size="15" color="#000000" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={handleShowDate}
        />
      </div>
      {logbookList?.length === 0 ? (
        <React.Fragment>
          <div className={styles.noRecordFound}>
            <p>
              {t("CURRENTLY_NO_WORKOUT")} -{" "}
              <span
                onClick={() => {
                  setAddLogbook(true);
                  setLogBookId("");
                  setSelectedWorkout("");
                }}
              >
                {t("ADD_WORKOUT")}
              </span>
            </p>
          </div>
          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        logbookList?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={styles.workoutDetail}>
                <div className={styles.workoutDetailLabel}>
                  <p>
                    {t("WORKOUT_")} <span>{t("TYPE")}</span>
                  </p>
                  <h1>{item.workoutType}</h1>
                </div>

                <div className={styles.devider}></div>

                <div className={styles.workoutDetailLabel}>
                  <p>
                    {t("EFFORT")} <span>{t("LEVEL")}</span>
                  </p>
                  <h1>
                    {item.effortLevel === CONSTANT.EASY
                      ? "Easy"
                      : item.effortLevel === CONSTANT.NORMAL
                      ? "Normal"
                      : item.effortLevel === CONSTANT.HARD
                      ? "Hard"
                      : "Unknown"}
                  </h1>
                </div>

                {item.isDistance === 1 && (
                  <>
                    <div className={styles.devider}></div>

                    <div className={styles.duration}>
                      <div className={styles.durationLabel}>
                        <p>{t("DIST")}</p>
                        <h1>
                          {item.distance}
                          {t("KM")}
                        </h1>
                      </div>

                      <div className={styles.durationLabel}>
                        <p>{t("TIME_")}</p>
                        <h1>{item.time}</h1>
                      </div>

                      <div className={styles.durationLabel}>
                        <p>{t("PACE")}</p>
                        <h1>{item.pace}</h1>
                      </div>
                    </div>
                  </>
                )}

                {!isContentEmpty(item.notes) && (
                  <>
                    <div className={styles.devider}></div>
                    <div className={styles.workoutDetailLabel}>
                      <p>
                        {t("WORKOUT_")} <span>{t("NOTES")}</span>
                      </p>
                      <div
                        className={styles.textEditorText}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.notes),
                        }}
                      ></div>
                    </div>
                  </>
                )}

                {!isContentEmpty(item.coachComments) && (
                  <>
                    <div className={styles.coachCommentBox}>
                      <p>
                        {t("COACH_")} <span>{t("COMMENTS")}</span>
                      </p>
                      <div
                        className={styles.textEditorText}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.coachComments),
                        }}
                      ></div>
                    </div>
                  </>
                )}

                <div className={styles.optionBox}>
                  <CustomButton
                    variant={"cancelBtn"}
                    type="button"
                    onClick={() => {
                      handleShowDelete();
                      setLogBookId(item.memberWorkoutId);
                    }}
                  >
                    <RiDeleteBin6Line
                      size="20"
                      color="#000000"
                      marginRight="10px"
                    />
                    {t("DELETE_BTN")}
                  </CustomButton>

                  <CustomButton
                    variant={"cancelBtn"}
                    type="button"
                    onClick={() => {
                      handleEdit(item);
                      setLogBookId(item.memberWorkoutId);
                      setAddLogbook(true);
                    }}
                  >
                    <MdOutlineModeEdit
                      size="20"
                      color="#000000"
                      marginRight="5px"
                    />
                    {t("EDIT")}
                  </CustomButton>
                </div>
              </div>
              {addLogbook && logBookId === item.memberWorkoutId ? (
                <EditLogbook
                  member_id={member_id}
                  workoutData={selectedWorkout}
                  logbookList={logbookList}
                  addLogbook={addLogbook}
                  setAddLogbook={setAddLogbook}
                  setLogBookId={setLogBookId}
                  logBookId={logBookId}
                  handleGetLogbook={handleGetLogbook}
                  setSelectedWorkout={setSelectedWorkout}
                  handleDeleteLogbook={handleDeleteLogbook}
                  setDay1={setDay}
                  setMonth1={setMonth}
                  setYear1={setYear}
                  setSelectedDate1={setSelectedDate}
                />
              ) : (
                ""
              )}
            </React.Fragment>
          );
        })
      )}

      <div className={styles.addWorkout}>
        <div className={styles.addWorkoutContent}>
          <CustomButton
            variant="footerBtnCustom"
            type="submit"
            onClick={() => {
              setAddLogbook(true);
              setLogBookId("");
              setSelectedWorkout("");
            }}
          >
            <p>
              {t("ADD")} <span>{t("WORKOUT_TITLE_TEXT")}</span>
            </p>
          </CustomButton>
          <div className={styles.displayResult}>
            <p>
              {totalCount === 0
                ? t("NO_RESULTS")
                : totalCount === 1
                ? `1 ${t("Result")}`
                : `${totalCount} ${t("Results")}`}
            </p>
          </div>
        </div>
      </div>
      {addLogbook && !logBookId ? (
        <EditLogbook
          member_id={member_id}
          logBookId={logBookId}
          workoutData={selectedWorkout}
          setSelectedWorkout={setSelectedWorkout}
          logbookList={logbookList}
          addLogbook={addLogbook}
          setAddLogbook={setAddLogbook}
          setLogBookId={setLogBookId}
          handleGetLogbook={handleGetLogbook}
          setDay1={setDay}
          setMonth1={setMonth}
          setYear1={setYear}
          setSelectedDate1={setSelectedDate}
        />
      ) : (
        ""
      )}
      <CalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
        eventData={CONSTANT.ALLOW_PREVIOUS}
        selectedDate={selectedDate}
      />
      <DeleteLogbook
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteGroup={handleDeleteLogbook}
      />
    </div>
  );
}

export default LogBook;
