import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-bootstrap/Modal";
import styles from "./DatePicker.module.css";
import { useTranslation } from "react-i18next";
import {
  DAYS_OF_WEEK,
  MONTH_OF_YEAR,
} from "../../AppComponents/Members/MockData";
import Lessthan from "../../Assests/Images/Lessthan.png";
import Greatethan from "../../Assests/Images/Greatethan.svg";

import Calender1 from "../../Assests/Images/Calender1.png";
import Calender2 from "../../Assests/Images/Calender2.png";
import { resetTimeToMidnight } from "../../Utils/CommonFunction";

function CalenderForMembership({
  show,
  handleClose,
  setWeekday,
  setDay,
  setMonth,
  setYear,
  previousRef,
  nextRef,
  tileDisabled,
  selectedDate,
}) {
  const { t } = useTranslation();

  const currentDate = new Date();
  const [value, onChange] = useState(
    selectedDate ? new Date(selectedDate) : new Date()
  );

  const [view, setView] = useState("month");
  const date = new Date(selectedDate); // Create a Date object
  const [currentMonthName, setCurrentMonthName] = useState("");
  const [currentYearName, setCurrentYearName] = useState("");

  useEffect(() => {
    if (show) {
      // When the calendar is opened, set the current year and month
      const initialDate = selectedDate ? new Date(selectedDate) : new Date();
      setCurrentMonthName(
        initialDate.toLocaleDateString("en-US", { month: "long" })
      );
      setCurrentYearName(initialDate.getFullYear());

      // Set the value to the initial date
      onChange(initialDate);
    }
  }, [show, selectedDate]);

  const handleViewChange = useCallback(
    ({ activeStartDate, view }) => {
      setView(view);
      // Handle month view
      if (view === "month") {
        const monthName = activeStartDate.toLocaleDateString("en-US", {
          month: "long",
        });
        const year = activeStartDate.getFullYear(); // Get the year
        setCurrentMonthName(monthName);
        setCurrentYearName(year); // Assuming you have a state to set the current year
      }
      // Handle year view or other views
      if (view === "year") {
        const year = activeStartDate.getFullYear();
        setCurrentYearName(year); // Set the year for year view
      }
    },
    [show]
  );

  const goToPreviousMonth = () => {
    if (resetTimeToMidnight(value) <= resetTimeToMidnight(currentDate)) {
      return; // Prevent navigating to the past
    }
    const previousDate = new Date(value);
    previousDate.setDate(previousDate.getDate() - 1);
    onChange(previousDate);
    updateDateState(previousDate);
  };

  const goToNextMonth = () => {
    const nextDate = new Date(value);
    nextDate.setDate(nextDate.getDate() + 1);
    onChange(nextDate);
    updateDateState(nextDate);
  };

  const updateDateState = (date) => {
    const weekday = date.toLocaleString("default", { weekday: "long" });
    const monthName = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const day = date.getDate();
    setWeekday(weekday);
    setDay(day);
    setMonth(monthName);
    setYear(year);
  };

  React.useImperativeHandle(previousRef, () => ({
    handleCallPrevious: goToPreviousMonth,
  }));

  React.useImperativeHandle(nextRef, () => ({
    handleCallNext: goToNextMonth,
  }));

  const handleDateClick = (date) => {
    updateDateState(date);
    handleClose();
  };

  const goToToday = () => {
    const today = new Date();
    onChange(today);
    updateDateState(today);
    handleClose();
  };

  useEffect(() => {
    if (selectedDate) {
      const predefautDate = new Date(selectedDate);
      onChange(predefautDate);
    }
  }, [show]);

  useEffect(() => {
    const monthName = value.toLocaleDateString("en-US", { month: "long" });
    setCurrentMonthName(monthName);
  }, [value]);

  // Check if selectedDate is valid before using substring
  var dateString = selectedDate ? new Date(selectedDate).toISOString() : null;
  var month = dateString
    ? MONTH_OF_YEAR[Number(dateString.substring(5, 7)) - 1]
    : "";

  const isPreviousDisabled =
    resetTimeToMidnight(value) <= resetTimeToMidnight(currentDate) ||
    (selectedDate &&
      resetTimeToMidnight(new Date(selectedDate)) <=
        resetTimeToMidnight(currentDate));

  const isNextDisabled =
    selectedDate &&
    resetTimeToMidnight(new Date(selectedDate)) <
      resetTimeToMidnight(currentDate);

  // ====Allow the "Today" button if it's not today already=====
  const isTodayDisabled =
    resetTimeToMidnight(value) === resetTimeToMidnight(currentDate);

  const CalenderSelectedDate = new Date(value);
  const CalenderMonthName = CalenderSelectedDate?.toLocaleDateString("en-US", {
    month: "long",
  });

  const CurrentDate = new Date(value);
  const CurrentMonthYear = CurrentDate?.getFullYear();

  return (
    <Modal show={show} onHide={handleClose} className="dateModal">
      <div>
        <Calendar
          onChange={onChange}
          value={value}
          onClickDay={handleDateClick}
          tileDisabled={tileDisabled}
          onActiveStartDateChange={handleViewChange}
        />
        {currentYearName === CurrentMonthYear &&
        currentMonthName === CalenderMonthName
          ? view === "month" && (
              <div className={styles.modalBtnSection}>
                <button
                  onClick={goToPreviousMonth}
                  className={styles.previousOrNext}
                  disabled={isPreviousDisabled}
                  style={{
                    opacity: isPreviousDisabled ? 0.5 : 1,
                    cursor: isPreviousDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  <img src={Lessthan} alt="lessthan" height="19px" />
                  <img
                    src={Calender2}
                    alt="Calender2"
                    className={styles.calenderChange}
                  />
                </button>

                <button
                  className={styles.showDay}
                  onClick={goToToday}
                  disabled={isTodayDisabled}
                  style={{
                    opacity: isTodayDisabled ? 0.5 : 1,
                    cursor: isTodayDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  {t("TODAY")}
                </button>

                <button
                  onClick={!isNextDisabled ? goToNextMonth : null}
                  className={styles.previousOrNext}
                  style={{
                    opacity: isNextDisabled ? 0.5 : 1,
                    cursor: isNextDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src={Calender1}
                    alt="Calender2"
                    className={styles.calenderChange}
                  />
                  <img src={Greatethan} alt="greater" height="19px" />
                </button>
              </div>
            )
          : ""}
      </div>
    </Modal>
  );
}

export default CalenderForMembership;
