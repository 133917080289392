import styles from "./Distance.module.css";
import Cross from "../../Assests/Images/cross.png";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import { Form } from "react-bootstrap";
import { userLogoutClear } from "../../store/slices/userSlice";
import SublyApi from "../../Helpers/Api";
import Toster from "../../Utils/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Utils/Loader/Loader";
import DeleteMember from "../Members/DeleteMember";
import { STATUS_MSG } from "../../Utils/StatusMsg";

function Distance({
  showAdd,
  setShowAdd,
  distanceId,
  setDistanceId,
  hours,
  setHours,
  minutes,
  setMinutes,
  distanceUnit,
  setDistanceUnit,
}) {
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [distanceList, setDistanceList] = useState("");
  const [totalCount, setTotalCount] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  //======== Function to toggle visibility==========
  const toggleMilesKm = () => {
    setShowAdd(true);
    setDistanceId("");
    setHours(0);
    setMinutes(0);
    setDistanceUnit("KM");
    setErrorMessage("");
  };

  // Button selected distance unit
  const handleButtonClick = (unit) => {
    setDistanceUnit(unit);
  };

  //===== new code 23/oct
  const handleHoursChange = (e) => {
    let value = e.target.value;
    if (value.length > 1 && value.startsWith("0")) {
      value = value.replace(/^0+/, "");
    }
    if (value.includes(".") || value > 999) return;
    setHours(parseInt(value, 10));
    const minutesValue = parseFloat(minutes) || 0;
    if (parseInt(value, 10) > 0 || minutesValue > 0) {
      setErrorMessage("");
    } else {
      setErrorMessage(t("ERROR_DISTANCE"));
    }
  };

  const handleMinutesChange = (e) => {
    let value = e.target.value;
    if (value.length > 1 && value.startsWith("0")) {
      value = value.replace(/^0+/, "0");
    }
    if (/^\d*$/.test(value) && value <= 99) {
      setMinutes(value);

      const hoursValue = parseFloat(hours) || 0;
      if (hoursValue > 0 || parseFloat(value) > 0) {
        setErrorMessage("");
      }
    }
  };

  //==== function for distance list api====
  const handleDistanceList = () => {
    setIsLoading(true);
    const response = SublyApi.getDistance(userToken);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setDistanceList(response?.data.distanceList);
        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    handleDistanceList("");
  }, []);

  //==== function for delete distance=====
  const handleDeleteDistance = () => {
    const response = SublyApi.DeleteDistance(userToken, distanceId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (response?.code === STATUS_CODES.SUCCESS) {
        Toster(t("DISTANCE_DELETED"), "success");
        handleDistanceList("");
        setShowDelete(false);
      } else if (response.data.code == STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else if (response.data.code == STATUS_CODES.CANT_DELETE_DISTANCE) {
        Toster(t("CANT_DELETE_DISTANCE"), "error");
      } else if (response?.data?.code === STATUS_CODES.INVALID_TOKEN) {
        Toster(response.data.message, "error");
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  // ======  function for calling save workout type=====
  const addDistance = () => {
    const hoursValue = parseFloat(hours) || 0;
    const minutesValue = parseFloat(minutes) || 0;
    if (hoursValue === 0 && minutesValue === 0) {
      setErrorMessage(t("ERROR_DISTANCE"));
      return;
    }
    const PostData = new FormData();
    if (distanceId) {
      PostData.append("distanceId", distanceId);
    }
    const formattedMinutes =
      minutesValue < 10 ? `0${minutesValue}` : minutesValue;
    const distance = `${hoursValue}.${formattedMinutes}`;
    PostData.append("distance", distance);
    PostData.append("distanceUnit", distanceUnit === "KM" ? 1 : 2);
    setIsLoading(true);
    SublyApi.saveDistance(userToken, PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODES.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODES.SUCCESS) {
          setIsLoading(false);
          Toster(
            distanceId ? t("DISTANCE_UPDATE") : t("ADD_DISTANCE"),
            "success"
          );
          setShowAdd(false);
          handleDistanceList("");
        } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //=== handle scroll bottom behaviour===
  const addFormref = useRef(null);
  useEffect(() => {
    if (addFormref.current && showAdd) {
      addFormref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [showAdd, distanceId]);

  return (
    <div className={styles.distanceDEtailWrapper}>
      {isLoading && <Loader />}
      {distanceList?.length === 0 ? (
        <p className={styles.noWork}>{t("NO_DISTANCE_FOUND")} </p>
      ) : (
        distanceList?.map((item, index) => {
          return (
            <div className={styles.distanceShow} key={index}>
              <div className={styles.disRange}>
                <p>
                  {item.distance}
                  <span>{item.distance_unit == 1 ? "KM" : "MI"} </span>
                </p>
              </div>
              <div className={styles.iconItem}>
                <div className={styles.editCross}>
                  <CustomImgButton
                    variant="primary"
                    src={Cross}
                    alt="cross"
                    onClick={() => {
                      handleShowDelete(item.id);
                      setDistanceId(item.id);
                    }}
                  ></CustomImgButton>
                </div>
              </div>
            </div>
          );
        })
      )}

      <div className={styles.distanceBtn}>
        <div className={styles.distanceCount}>
          <p>
            {totalCount}{" "}
            <span>{totalCount <= 1 ? "Distance" : "Distances"}</span>
          </p>
          <CustomButton
            variant="footerBtnCustom"
            type="submit"
            onClick={toggleMilesKm}
          >
            <p>
              {t("ADD")} <span>{t("DISTANCE")}</span>
            </p>
          </CustomButton>
        </div>
      </div>

      {/* show add/edit distance form */}
      {showAdd && (
        <div className={styles.addDistanceSection} ref={addFormref}>
          <div className={styles.addDistanceBox}>
            <p>
              {t("ADD")} <span>{t("DISTANCE")}</span>
            </p>
          </div>

          <div className={styles.distanceSection}>
            <div className={styles.distanceData}>
              <div className={styles.distance}>
                <div className={styles.timeInput}>
                  <input
                    // type="number"
                    name="hours"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={hours ? hours : 0}
                    maxLength="23"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        (/^\d+$/.test(value) && value.length <= 3) ||
                        value === ""
                      ) {
                        handleHoursChange(e);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (
                        event.key === "." ||
                        event.key === "e" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    max="999"
                  />
                </div>
                <p className={styles.dot}>.</p>
                <div className={styles.timeInputMin}>
                  <input
                    type="number"
                    name="minutes"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={minutes}
                    maxLength="2"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        (/^\d+$/.test(value) && value.length <= 2) ||
                        value === ""
                      ) {
                        handleMinutesChange(e);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (
                        event.key === "." ||
                        event.key === "e" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <h6>{distanceUnit}</h6>
              </div>
              <div className="switch-field">
                <Form.Check
                  type="radio"
                  id="radio-one"
                  name="switch-one"
                  label={t("KILOMETER")}
                  checked={distanceUnit === "KM"}
                  onClick={() => handleButtonClick("KM")}
                />
                <Form.Check
                  type="radio"
                  id="radio-two"
                  name="switch-one"
                  label={t("MILES")}
                  checked={distanceUnit === "MI"}
                  onClick={() => handleButtonClick("MI")}
                />
              </div>
            </div>
            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
          </div>

          <div className={styles.addBottomBtn}>
            <div className={styles.btnSection}>
              <CustomButton
                variant="cancelBtn"
                type="button"
                onClick={() => {
                  setShowAdd(false);
                }}
              >
                {t("CANCEL")}
              </CustomButton>

              <CustomButton
                variant="footerBtnCustom"
                type="submit"
                onClick={() => addDistance()}
              >
                <p>
                  {t("SAVE_BUTTON")} <span>{t("DISTANCE")}</span>
                </p>
              </CustomButton>
            </div>
          </div>
        </div>
      )}

      <DeleteMember
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteMember={handleDeleteDistance}
      />
    </div>
  );
}

export default Distance;
