export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const ValidationSchema = {
  EMAIL: {
    required: "EMAIL_REQUIRED",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "INVALID_EMAIL",
    },
    minLength: {
      value: 5,
      message: "EMAIL_MUST_5_CH",
    },
    maxLength: {
      value: 100,
      message: "EMAIL_NOT_MORE_THAN_6_CH",
    },
  },
  PASSWORD: {
    required: "PASSWORD_REQUIRED_MESSAGE",
    minLength: {
      value: 6,
      message: "PASS_MINLENGTH",
    },
    maxLength: {
      value: 12,
      message: "PASS_MAXLENGTH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  CON_PASSWORD: {
    required: "password not matched",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  NAME: {
    required: "NAME_IS_REQUIRED",

    maxLength: {
      value: 60,
      message: "TITLE_NOT_MORE_THAN_6_CH",
    },
  },

  // PHONE_INPUT: {
  //   required: "Please_ENTER_MOBILE_NO",
  // },
  CONTACT_NO: {
    required: "Please enter contact number",
  },

  EMAIL_OTP: {
    required: "PLS_ENTER_EMAIL_OTP",
    minLength: {
      value: 4,
      message: "PLS_FILL_COMPLETE_OTP",
    },
  },

  ADD_BRAND_NAME: {
    required: "BRAND_NAME_REQUIRED_MESSAGE",
    maxLength: {
      value: 60,
      message: "TITLE_NOT_MORE_THAN_6_CH",
    },
  },


  BRAND_DETAIL: {
    required: "PLS_ENTER_BRAND_DETAIL",
  },

  CONTACT_EMAIL: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "VALID_EMAIL_ADDRESS",
    },
  },
  WEBSITE_ADDRESS: {
    maxLength: {
      value: 255,
      message: "WEBADDRESS_MAX_250_CH",
    },
    pattern: {
      value: /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)?$/i,
      message: "VALID_WEB_ADDRESS",
    },
  },
  // COUNTRY  : {
  //     required: "PLS_SELECT_COUNTRY",
  //   },
  STATE: {
    required: "PLS_SELECT_STATE",
  },
  TOWN_CITY: {
    required: "ENTER_TOWN_CITY",
    maxLength: {
      value: 60,
      message: "TOWN_CITY_MAX_255_CH",
    },
  },

  SELECT_BRAND: {
    required: "PLS_SELECT_BRAND",
  },
  // PHYSICAL_ADDRESS: {
  //   required: "ENTER_ADDRESS",
  //   maxLength: {
  //     value: 60,
  //     message: "ADDRESS_MAX_60_CH",
  //   },
  // },
  // GOOGLE_ADDRESS: {
  //   maxLength: {
  //     value: 255,
  //     message: "GOOGLE_ADDRESS_MAX_255_CH",
  //   },
  // },
  BUTTON_LABEL: {
    required: "PLS_ENTER_BTN_LABEL",
    maxLength: {
      value: 50,
      message: "BUTTON_LABEL_MAX_50_CH",
    },
  },
  BUTTON_Link: {
    required: "ENTER_BUTTON_LINK",
    maxLength: {
      value: 255,
      message: "BUTTON_LINK_MAX_255_MAX",
    },
    pattern: {
      value: /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)?$/i,
      message: "ENTER_VALID_LINK",
    },
  },

  // BUTTON_TEXT_COLOR: {
  //   required: "ENTER_BUTTON_TEXT",
  //   maxLength: {
  //     value: 7,
  //     message: "BUTTON_TEXT_MAX_7_CH",
  //   },
  //   pattern: {
  //     value: /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/,
  //     message: "ENTER_VALID_COLOR",
  //   },
  // },
  BACKGROUND_COLOR: {
    required: "PLS_ENTER_BACKGROUND",
    maxLength: {
      value: 7,
      message: "BACKGROUND_MAX_7_CH",
    },
    pattern: {
      value: /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/,
      message: "ENTER_VALID_COLOR",
    },
  },
  TEXT_COLOR: {
    required: "ENTER_TEXT_COLOR",
    maxLength: {
      value: 7,
      message: "TEXT_COLOR_MAX_7_CH",
    },
    pattern: {
      value: /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/,
      message: "ENTER_VALID_COLOR",
    },
  },
  VIDEO: {
    required: "UPLOAD_APP_LOGO",
  },

  GROUP_NAME: {
    required: "PLZ_ENTER_GRP_NAME",
    maxLength: {
      value: 50,
      message: "GRP_NAME_MAX_50_CH",
    },
  },

  ABOUT_INFO: {
    required: "PLZ_ENTER_INFO",
  },
  FIRST_NAME: {
    required: "Please enter member first name",
    maxLength: {
      value: 20,
      message: "First name should not be more than 20 characters",
    },
  },

  // ADD_EMAIL: {
  //   pattern: {
  //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  //     message: "VALID_EMAIL_ADDRESS",
  //   },
  // },
  // DOB: {
  //   required: "Please select date of birth",
  // },
  VALID_UPTO: {
    required: "Please select date",
  },
  GENDER: {
    required: "Please select gender",
  },
  WEIGHT: {
    required: "Please select weight",
  },

  TITLE: {
    required: "Please enter title",
    maxLength: {
      value: 50,
      message: "Title should not be more than 50 characters",
    },
  },
  START_DATE: {
    required: "Please select start date",
  },
  LOCATION_NAME: {
    required: "Please enter location name",
    maxLength: {
      value: 250,
      message: "Location name should not be more than 250 characters",
    },
  },
  RESULT_DISTANCE: {
    required: "Please select result distance",
  },

  // COUNTRY: {
  //   required: "PLS_SELECT_COUNTRY",
  // },

  MEMBER_NAME: {
    required: "PLEASE_ENTER_MEMBER_NAME",
  },

  PHONE_INPUT: {
    required: "Please_ENTER_MOBILE_NO",
  },
  // CONTACT_NO: {
  //   required: "ENTER_CONTECT_NO",
  // },

  // EMAIL_OTP: {
  //   required: "PLS_ENTER_EMAIL_OTP",
  //   minLength: {
  //     value: 4,
  //     message: "PLS_FILL_COMPLETE_OTP",
  //   },
  // },

  BRAND_NAME: {
    required: "NAME_IS_REQUIRED",
    maxLength: {
      value: 60,
      message: "TITLE_NOT_MORE_THAN_6_CH",
    },
  },
  // BRAND_DETAIL: {
  //   required: "PLS_ENTER_BRAND_DETAIL",
  // },

  // CONTACT_EMAIL: {
  //   pattern: {
  //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  //     message: "VALID_EMAIL_ADDRESS",
  //   },
  // },
  // WEBSITE_ADDRESS: {
  //   maxLength: {
  //     value: 255,
  //     message: "WEBADDRESS_MAX_250_CH",
  //   },
  //   pattern: {
  //     value: /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)?$/i,
  //     message: "VALID_WEB_ADDRESS",
  //   },
  // },
  // COUNTRY: {
  //   required: "PLS_SELECT_COUNTRY",
  // },
  // STATE: {
  //   required: "PLS_SELECT_STATE",
  // },
  // TOWN_CITY: {
  //   required: "ENTER_TOWN_CITY",
  //   maxLength: {
  //     value: 60,
  //     message: "TOWN_CITY_MAX_255_CH",
  //   },
  // },
  PHYSICAL_ADDRESS: {
    required: "ENTER_ADDRESS",
    maxLength: {
      value: 60,
      message: "ADDRESS_MAX_60_CH",
    },
  },
  GOOGLE_ADDRESS: {
    maxLength: {
      value: 255,
      message: "GOOGLE_ADDRESS_MAX_255_CH",
    },
  },
  // BUTTON_LABEL: {
  //   required: "PLS_ENTER_BTN_LABEL",
  //   maxLength: {
  //     value: 50,
  //     message: "BUTTON_LABEL_MAX_50_CH",
  //   },
  // },
  // BUTTON_Link: {
  //   required: "ENTER_BUTTON_LINK",
  //   maxLength: {
  //     value: 255,
  //     message: "BUTTON_LINK_MAX_255_MAX",
  //   },
  //   pattern: {
  //     value: /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)?$/i,
  //     message: "ENTER_VALID_LINK",
  //   },
  // },

  BUTTON_TEXT_COLOR: {
    required: "ENTER_BUTTON_TEXT",
    maxLength: {
      value: 7,
      message: "BUTTON_TEXT_MAX_7_CH",
    },
    pattern: {
      value: /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/,
      message: "ENTER_VALID_COLOR",
    },
  },
  // BACKGROUND_COLOR: {
  //   required: "PLS_ENTER_BACKGROUND",
  //   maxLength: {
  //     value: 7,
  //     message: "BACKGROUND_MAX_7_CH",
  //   },
  //   pattern: {
  //     value: /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/,
  //     message: "ENTER_VALID_COLOR",
  //   },
  // },
  // TEXT_COLOR: {
  //   required: "ENTER_TEXT_COLOR",
  //   maxLength: {
  //     value: 7,
  //     message: "TEXT_COLOR_MAX_7_CH",
  //   },
  //   pattern: {
  //     value: /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/,
  //     message: "ENTER_VALID_COLOR",
  //   },
  // },
  // VIDEO: {
  //   required: "UPLOAD_APP_LOGO",
  // },

  // GROUP_NAME: {
  //   required: "PLZ_ENTER_GRP_NAME",
  //   maxLength: {
  //     value: 50,
  //     message: "GRP_NAME_MAX_50_CH",
  //   },
  // },

  // ABOUT_INFO: {
  //   // required: "PLZ_ENTER_INFO",
  //   maxLength: {
  //     value: 200,
  //     message: "ABOUT_INFO_MAX",
  //   },
  // },
  // FIRST_NAME: {
  //   required: "ENTER_FIRST_NAME",
  //   maxLength: {
  //     value: 20,
  //     message: "FIRST_NAME_MAX_20_CH",
  //   },
  // },
  LAST_NAME: {
    required: "ENTER_SURNAME_LAST_NAME",
    maxLength: {
      value: 20,
      message: "SURBNAME_MAX_20_CH",
    },
  },
  ADD_EMAIL: {
    required: "ENTER_EMAIL_ADDRESS",

    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "VALID_EMAIL_ADDRESS",
    },
  },
  DOB: {
    required: "SELECT_DOB",
  },
  // VALID_UPTO: {
  //   required: "SELECT_DATE_",
  // },
  // GENDER: {
  //   required: "SELECT_GENDER",
  // },
  // WEIGHT: {
  //   required: "WEIGHT",
  // },

  // TITLE: {
  //   required: "PLEASE_TITLE",
  //   maxLength: {
  //     value: 50,
  //     message: "TITLE_MAX_50_CH",
  //   },
  // },
  NEWS_TITLE: {
    required: "PLEASE_TITLE",
    maxLength: {
      value: 100,
      message: "NEWS_TITLE_MAX_100_CH",
    },
  },
  // START_DATE: {
  //   required: "START_DATE",
  // },
  // LOCATION_NAME: {
  //   required: "SELECT_LOCATION_NAME",
  //   maxLength: {
  //     value: 250,
  //     message: "LOCATION_NAME_MAX_250_CH",
  //   },
  // },
  // RESULT_DISTANCE: {
  //   required: "SELECT_RESULT_DISTANCE",
  // },
  START_TIME: {
    required: "START_TIME",
  },
  END_TIME: {
    required: "END_TIME",
  },
  REPEATING_DAYS: {
    required: "REPEATING_DAYS",
  },
  REPEATING_TIME: {
    required: "SELECT_REPEATING_TIME",
  },
  EVENT: {
    required: "SELECT_EVENT",
  },
  MEMBER: {
    required: "SELECT_MEMBER",
  },
  DISTANCE: {
    required: "SELECT_DISTANCE",
  },
  TIME: {
    required: "SELECT_TIME_",
  },
  ROUTENAME: {
    required: "ROUTENAME_IS_REQUIRED",
    maxLength: {
      value: 255,
      message: "ROUTE_NAME_MAX_255_MAX",
    },
  },
  ROUTE_Link: {
    required: "ENTER_ROUTE_LINK",
    maxLength: {
      value: 255,
      message: "ROUTE_LINK_MAX_255_MAX",
    },
  },
  ROUTE_LINK_IN_ROUTE: {
    maxLength: {
      value: 255,
      message: "ROUTE_LINK_MAX_255_MAX",
    },
  },
  QUESTION: {
    required: "ENTER_QUESTION_",
    maxLength: {
      value: 255,
      message: "QUESTION_MAX_255_CH",
    },
  },
  answers: {
    required: "ANSWER_REQUIRED",
  },
  WORKOUT_TYPES: {
    required: "WORKOUT_TYPE_IS_REQUIRED",

    maxLength: {
      value: 255,
      message: "TYPE_NOT_MORE_THAN_255_CH",
    },
  },


  ADD_WORKOUT_TYPES: {
    required: "WORKOUT_TYPE_IS_REQUIRED",

    maxLength: {
      value:50,
      message: "TYPE_NOT_MORE_THAN_50_CH",
    },
  },
  WORKOUT_TITLE: {
    required: "WORKOUT_TITLE_IS_REQUIRED",
    maxLength: {
      value: 255,
      message: "TYPE_NOT_MORE_THAN_255_CH",
    },
  },

  WORKOUT_DETAIL: {
    required: "PLS_ENTER_WORKOUT_DETAIL",
  },

  ROUTES_TYPES: {
    required: "ROUTES_TYPE_IS_REQUIRED",

    maxLength: {
      value: 255,
      message: "TYPE_NOT_MORE_THAN_255_CH",
    },
  },
  NOTIFICATION_TEXT: {
    required: "NOTIFICATION_MSG",
    maxLength: {
      value: 255,
      message: "MESSAGE_MAX_50_CH",
    },
  },
  ADD_VEDIO: {
    maxLength: {
      value: 255,
      message: "VIDEO_LINK_MAX_255_CH",
    },
  },
};
