import React, { useEffect, useState, useRef } from "react";
import styles from "./Members.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { CustomBrandSelect } from "../../ReactBootstrap/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { fetchBrandList, userLogoutClear } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useNavigate } from "react-router-dom";
import debounce from "debounce";
import ProfileDemo from "../../Assests/Images/ProfileDemo.svg";
import SublyApi from "../../Helpers/Api";
import Toster from "../../Utils/Toster";
import { capitalizeFirstLetter } from "../../Utils/CommonFunction";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Utils/Loader/Loader";
import { CONSTANT } from "../../Utils/Constant";
import { RxCross2 } from "react-icons/rx";

function Invites() {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [brandMember, setBrandMember] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedBrandId, setSelectedBrandId] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredBrandOptions, setFilteredBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  //===========function for form validation using useform===========
  const { register, formState, setValue, reset } = useForm({
    mode: "onChange",
  });

  //========== function for calling get brand list api==========
  async function getBrandList(
    pageNum = 1,
    brandSearch = "",
    onLoadMore = false,
    listForAddMember = 1
  ) {
    setIsLoading(true);
    await dispatch(
      fetchBrandList({
        userToken: userToken,
        page_no: pageNum,
        search: brandSearch,
        onLoadMore: onLoadMore,
        listForAddMember: listForAddMember,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      if (response && response.code && response.code === STATUS_CODES.SUCCESS) {
        const options = response.data.brandList.map((brand) => ({
          id: brand.id,
          name: brand.clubName,
        }));
        setIsLoading(false);
        setFilteredBrandOptions(options);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        setFilteredBrandOptions([]);
      }
    });
  }

  // useEffect hook to call getBrandList only once on initial render
  useEffect(() => {
    if (isFirstRender.current) {
      getBrandList();
      isFirstRender.current = false;
    }
  }, []);

  // =====function for handle brand =======
  const handleBrandChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBrand(selectedOption);
      setSelectedBrandId(selectedOption.value);
    } else {
      setSelectedBrand("");
      setSelectedBrandId("");
      setSearchKeyword("");
      setBrandMember([]);
    }
    setSearchKeyword("");
  };

  // =====function for handle brand search member=======
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchKeyword(value);
    setSelectedMember(null);
    if (!selectedBrandId) {
      setBrandMember([]);
      return;
    }
    if (value.trim() === "") {
      setBrandMember([]);
      setPageNumber(1);
      return;
    }
    debouncedSearch(value, selectedBrandId);
  };

  const debouncedSearch = useRef(
    debounce((search, brandId) => {
      if (brandId) {
        handleBrandMemberSearch(1, search, brandId);
      } else {
        Toster(
          t("Please select a brand before searching for members"),
          "error"
        );
      }
    }, 500)
  ).current;

  //====== function for handle member search=====
  const handleBrandMemberSearch = (page, search, brandId) => {
    setIsLoading(true);
    SublyApi.searchMemberOfBrand(userToken, page, search, brandId).then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODES.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setLoadMore(response?.data?.loadMore);

          setBrandMember((prevMembers) => {
            return page === 1
              ? response?.data?.memberList
              : [...prevMembers, ...response?.data?.memberList];
          });

          setPageNumber((prevPageNumber) => {
            return response?.data?.loadMore
              ? prevPageNumber + 1
              : prevPageNumber;
          });
        }
      }
    );
  };

  
  //======== function for handle highlight keyword===========
  // function HighlightKeyInParagraph({ keyWord, paragraph }) {
  //   const renderHighlightedParagraph = () => {
  //     const parts = paragraph?.split(new RegExp(`(${keyWord})`, "gi"));
  //     return parts.map((part, index) =>
  //       part.toLowerCase() === keyWord.toLowerCase() ? (
  //         <span
  //           key={index}
  //           style={{
  //             fontFamily: "Manrope",
  //             fontWeight: "600",
  //             fontSize: "15px",
  //             background: "#FF4300",
  //             margin: "0px",
  //             color: "#FFFFFF",
  //           }}
  //         >
  //           {part}
  //         </span>
  //       ) : (
  //         part
  //       )
  //     );
  //   };

  //   return <h1 style={{ margin: "0px" }}>{renderHighlightedParagraph()}</h1>;
  // }

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      // Ensure paragraph is a string, use an empty string as a fallback if not
      const text = typeof paragraph === 'string' ? paragraph : "";
      const parts = text.split(new RegExp(`(${keyWord})`, "gi"));
      return parts.map((part, index) =>
        part.toLowerCase() === keyWord.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "15px",
              background: "#FF4300",
              margin: "0px",
              color: "#FFFFFF",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };
  
    return <h1 style={{ margin: "0px" }}>{renderHighlightedParagraph()}</h1>;
  }
  
  

  //===== function for select member for invite=====
  const handleSelectMember = (member) => {
    setSelectedMember(member);
  };

  //======= function for calling send invite api======
  const handleSendInvitation = () => {
    if (selectedBrandId && selectedMember && searchKeyword) {
      const PostData = new FormData();
      PostData.append("memberId", selectedMember.id);
      PostData.append("brandId", selectedBrandId);
      setIsLoading(true);
      SublyApi.SendInvitation(userToken, PostData).then((response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODES.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response?.code === STATUS_CODES.SUCCESS) {
            setSelectedBrandId("");
            setSelectedBrand("");
            setValue("SELECT_BRAND", "Select Brand");
            Toster(t("Invitation sent successfully"), "success");
            setSelectedMember("");
            setSearchKeyword("");
            reset();
            setBrandMember([]);
          } else if (response?.data?.code === STATUS_CODES.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.inviteBoxHead}>
        <p>
          {t("INVITE_MEMBER")}
          <span>{t("MEMBER_BTN")}</span>
        </p>
      </div>
      <div className={styles.inviteBox}>
        <div className={styles.selectBrand}>
          <CustomBrandSelect
            styleName="event"
            Label={<p>{t("BRAND")}</p>}
            RegisterName={"SELECT_BRAND"}
            register={register}
            formState={formState}
            arrayData={filteredBrandOptions}
            onChangeHandler={handleBrandChange}
            value={selectedBrand}
            disabled={false}
          />
          <div className={styles.crosIconSerch}>
            <RxCross2
              onClick={() => {
                setSelectedBrand("");
                setSelectedMember("");
                setBrandMember([]);
                setSearchKeyword("");
                setSelectedBrandId("");
              }}
            />
          </div>
        </div>

        <div className={styles.searchBox}>
          <p>
            {t("SEARCH")} <span>{t("MEMBER_BTN")}</span>
          </p>
          <input
            placeholder={t("SEARCH_MEMBER_NAME")}
            value={searchKeyword}
            onChange={handleSearchChange}
            disabled={!selectedBrandId && !searchKeyword}
          />
          <div className={styles.profleImg}>
            <img src={ProfileDemo} alt="ProfileDemo"></img>
          </div>
        </div>

        {searchKeyword !== "" && (
          <React.Fragment>
            {brandMember?.map((item, index) => (
              <div
                className={styles.searchResult}
                key={index}
                onClick={() => handleSelectMember(item)}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedMember?.id === item.id ? "#fff" : "#333",
                  color: selectedMember?.id === item.id ? "#000" : "#fff",
                }}
              >
                <div className={styles.searchInfo}>
                  <img
                    src={
                      item.profilePicture ? item.profilePicture : ProfileDemo
                    }
                    alt="ProfileDemo"
                  />
                  <div className={styles.userDetail}>
                    <HighlightKeyInParagraph
                      keyWord={searchKeyword}
                      paragraph={`${capitalizeFirstLetter(
                        item?.firstName
                      )} ${capitalizeFirstLetter(
                        item?.lastName ? item?.lastName : ""
                      )}`}
                    />
                    {/* <p>{item.email}</p> */}

                    <p>
                      <HighlightKeyInParagraph
                        keyWord={searchKeyword}
                        paragraph={capitalizeFirstLetter(item.email)}
                      />
                    </p>
                  </div>
                </div>
              </div>
            ))}

            {brandMember?.length === 0 && (
              <React.Fragment>
                <div className={styles.noDataFound}>
                  <p>
                    {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
                  </p>
                </div>
                <div className={styles.devider}></div>
              </React.Fragment>
            )}
            {brandMember?.length !== 0 ? (
              <div
                className={styles.searchResult}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <CustomButton
                    variant="footerBtnCustom"
                    onClick={() => {
                      if (loadMore) {
                        handleBrandMemberSearch(
                          pageNumber,
                          searchKeyword,
                          selectedBrandId
                        );
                      }
                    }}
                    opacity={loadMore ? "" : CONSTANT.OPACITY_RATIO}
                  >
                    {" "}
                    <p>
                      {t("LOAD")} <span>{t("MORE")}</span>
                    </p>
                  </CustomButton>
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        )}

        <div className={styles.inviteBtn}>
          <CustomButton
            variant="footerBtnCustom"
            type="button"
            onClick={handleSendInvitation}
            opacity={
              selectedBrandId &&
              selectedMember &&
              searchKeyword &&
              brandMember.length !== 0
                ? ""
                : CONSTANT.OPACITY_RATIO
            }
            disabled={
              !(selectedBrandId && selectedMember && brandMember.length !== 0)
            }
          >
            <p>
              {t("SEND_INVITE")}
              <span> {t("INVITE_BTN")} </span>
            </p>
          </CustomButton>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <p>{t("INVITE_NOTE")}</p>
      </div>
    </div>
  );
}

export default Invites;
