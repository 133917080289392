// Remove extra plus
export function removeExtraPlus(inputString) {
  let newStr = "+" + inputString;
  return newStr.replace(/\++/g, "+");
}

export const cleanData = (value) => {
  if (value === undefined || value === null) {
    return "";
  }
  return value;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

// export const formatDate = (dateString) => {
//   const options = { day: "2-digit", month: "long", year: "numeric" };
//   const date = new Date(dateString);
//   return date?.toLocaleDateString("en-GB", options);
// };

export const formatDate = (dateString) => {
  if (!dateString || dateString === "N/A") return "N/A";
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date?.toLocaleDateString("en-GB", options);
};



export const formatPhoneNumber = (number) => {
  const firstPart = number?.substring(0, 3);

  const secondPart = number?.substring(3, 6);

  const remainingPart = number?.substring(6);

  return `${firstPart}-${secondPart}-${remainingPart}`;
};

export const convertServerDataInOurArray = (data) => {
  return data?.map((item) => {
    const { distanceId, bestTime } = item;
    const [hours, minutes, seconds] = bestTime
      .split(":")
      .map((val) => parseInt(val));
    const createdAt = new Date().toISOString();
    return {
      id: distanceId,
      hours,
      createdAt,
      minutes,
      seconds: seconds || "0",
    };
  });
};

export const transformTimeArray = (dataArray) => {
  return dataArray?.map((item) => {
    const hours = parseInt(item.hours) || 0;
    const mins = parseInt(item.minutes) || 0; // Rename minutes to mins
    const seconds = parseInt(item.seconds) || 0;

    const time = `${hours < 10 ? "0" : ""}${hours}:${
      mins < 10 ? "0" : ""
    }${mins}:${seconds < 10 ? "0" : ""}${seconds}`;

    const date = new Date(item.createdAt);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours24 = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const secs = ("0" + date.getSeconds()).slice(-2);
    const createdAt = `${year}-${month}-${day} ${hours24}:${minutes}:${secs}`;

    return {
      id: item.id,
      time,
      createdAt,
    };
  });
};
