export const STATUS_MSG = {
  100: "HEADER_IS_MISSING",
  101: "DEVICE_TYPE_NOT_ALLOWED",
  102: "INVALID_API_KEY",
  103: "AUTH_TOKEN_IS_REQUIRED",
  104: "INVALID_TOKEN",
  105: "VALIDATION_ERROR",
  111: "INVALID_LOGIN_CREDENTIAL",
  112: "EMAIL_EXIST",
  113: "SESSION_EXPIRED",
  114: "BRAND_NAME_EXIST",
  115: "INVALID_VERIFICATION_CODE",
  116: "FAILED_TO_SEND_EMAIL",
  117: "INVALID_BRAND_ID",
  118: "INVALID_IMAGE_SIZE",
  119: "INVALID_BRAND_FILE_EXTENSION",
  120: "INVALID_APP_LOGO_IMAGE_SIZE",
  121: "INVALID_APP_LOGO_FILE_EXTENSION",
  122: "APP_LOGO_REQUIRED",
  123: "COUNTRY_ID_IS_REQUIRED",
  124: "INVALID_GROUP_ID",
  125: "EMAIL_AND_PHONE_NUMBER_EXIST",
  126: "INVALID_MEMBER_ID",
  127: "INVALID_MEMBER_PICTURE_SIZE",
  128: "INVALID_MEMBER_FILE_EXTENSION",
  129: "GROUP_NAME_EXIST",
  130: "INVALID_PERSONAL_BESTS_OBJECT",
  131: "INVALID_BRANDS_OBJECT",
  132: "MEMBER_LIMIT_EXCEEDED",
  133: "THE_GROUP_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION",
  134: "PHONE_NUMBER_EXIST",
  135: "PROFILE_PICTURE_REQUIRED",
  136: "INVALID_EVENT_ID",
  137: "INVALID_EVENT_PICTURE_SIZE",
  138: "INVALID_EVENT_FILE_EXTENSION",
  139: "INVALID_RESULT_DISTANCE_JSON_OBJECT",
  140: "INVALID_KEY_IN_RESULT_DISTANCE_JSON_OBJECT",
  141: "EVENT_NAME_ALREADY_EXIST",
  142: "MEMBER_IS_INACTIVE",
  143: "ACCOUNT_NOT_FOUND",
  144: "INVALID_PERSONAL_BESTS_JSON_OBJECT",
  145: "INVALID_BRANDS_JSON_OBJECT",
  146: "EVENT_GALLERY_REQUIRED",
  147: "INVALID_EVENT_GALLERY_PICTURE_SIZE",
  148: "INVALID_EVENT_GALLERY_FILE_EXTENSION",
  149: "BRANDS_FIELD_IS_REQUIRED",
  150: "INVALID_POLL_ANSWERS_JSON_OBJECT",
  151: "INVALID_KEY_IN_POLL_ANSWERS_JSON_OBJECT",
  152: "DUPLICATE_POLL_ANSWER_FOUND",
  153: "NUMBER_OF_POLL_ANSWER_CAN_BE_MAXIMUM_10",
  154: "INVALID_TRAINING_GROUPS_JSON_OBJECT",
  155: "ALREADY_A_MEMBER_OF_THIS_CLUB",
  156: "MEMBER_INVITATION_IS_ALREADY_SENT",

  157: "INVALID_EVENT_RESULT_ID",
  158: "INVALID_INVITATION_ID",
  159: "ACTION_ALREADY_TAKEN",
  160: "INVALID_EVENT_DISTANCE_ID",
  161: "EVENT_RESULT_ALREADY_EXISTS",
  162: "INVALID_WORKOUT_TYPE_ID",
  163: "WORKOUT_TYPE_EXISTS",
  164: "THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION",
  165: "FAILED_TO_SEND_SMS",
  166: "WORKOUT_TITLE_EXISTS",
  167: "INVALID_WORKOUT_ID",
  168: "INVALID_WORKOUT_PICTURE_SIZE",
  169: "INVALID_WORKOUT_PICTURE_FILE_EXTENSION",
  170: "THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION",
  171: "ROUTE_NAME_EXISTS",
  172: "INVALID_ROUTE_PICTURE_SIZE",
  173: "INVALID_ROUTE_PICTURE_FILE_EXTENSION",
  174: "INVALID_ROUTE_ID",
  175: "THE_ROUTE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION",
  176: "INVALID_ANSWER_ID",
  177: "NEWS_GALLERY_REQUIRED",
  178: "INVALID_NEWS_GALLERY_PICTURE_SIZE",
  179: "INVALID_NEWS_GALLERY_FILE_EXTENSION",
  180: "INVALID_NEWS_ID",
  181: "INVALID_TRAINING_ID",
  182: "INVALID_NEWS_PICTURE_SIZE",
  183: "INVALID_NEWS_FILE_EXTENSION",
  184: "NEWS_TITLE_EXISTS",
  185: "INVALID_EVENT_POLL_VOTE_ID",
  186: "POLL_VOTE_EXISTS",

  188: "POLL_VOTE_EXIST",
  195: "CANNOT_ADD_MORE_THAN_ONE_GRP",
  196: "CANNOT_ADD_MORE_THAN_THREE_WORKOUT_IMAGES",
  199: "MEMBER_NOT_PART_OF_GRP",
  201: "NOT_PART_OF_BRAND",
  203: "OLD_PASS_NOT_MATCH",
  206: "MEMBERSHIP_EXIST_FAILED",

  195: "PLS_ADD_A_GRP",
  197: "INVALID_DISTANCE_ID",
  208: "ALREADY_IN_USE",
  200: "SUCCESS",
  208: "ALREADY_IN_USE",
  212: "MEMBERSHIP_NO_LONGER_EXIST",
  214: "NO_REPETING_DAYS",
  216: "DISTANCE_EXIT",
  217: "CANT_DELETE_DISTANCE",
  218: "INVALID_PB_ID",
  219: "CANT_DELETE_PBDISTANCE",
  220: "PB_ALREADY_EXIT",
  500: "INTERNAL_SERVER_ERROR",
};
