import axios from "axios";

// const BASE_URL = "";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com/";
const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_ID, uint32.toString(32));
}

if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_TOKEN, uint32.toString(32));
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SublyApi {
  //token which interact with the API will be stored here.
  static token;
  //required common header for each api calling.
  static commonHeaders = {
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    // return url.replace(/\/\/+/g, '/');
    return url.replace(/([^:]\/)\/+/g, "$1");
  }
  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...SublyApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /******************************************************
   * USERS AUTH API ROUTES
   *******************************************************/

  /* POST  /login:  {device_id,device_type,password,email}
 Authorization required:none
 @param data {Object} {device_id,device_type,password,email}
 @returns {object} {token}
 */
  static async login(data) {
    let res = await this.request(`/superAdmin/login`, data, "post");
    return res;
  }

  // ----------Brand branding Api-----------
  static async HandleLogout(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/superAdmin/logout`, {}, "post", header);
    return res;
  }

  // ----------Brand List Api-----------
  static async brandList(authToken, pageNo, search, listForAddMember) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand?pageNo=${pageNo}&search=${search}&listForAddMember=${listForAddMember}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ----------country meta List Api-----------
  static async countryMetaData(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/countryMeta`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ----------country meta List Api-----------
  static async stateMetaData(authToken, countryId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/stateMeta?countryId=${countryId}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ----------Brand info Api-----------
  static async brandInfo(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand/brandInfo`,
      data,
      "post",
      header
    );
    return res;
  }

  // ----------Brand branding Api-----------
  static async brandBranding(data, authToken, brandId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand/${brandId}/branding`,
      data,
      "post",
      header
    );
    return res;
  }

  // ----------Brand subscription Api-----------
  static async brandSubscription(data, authToken, brandId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand/${brandId}/subscription`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ----- Change membership status  -----*/
  static async changeMemberShip(data, authToken, brandId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand/${brandId}/activeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  // ----------Brand Detail Api-----------
  static async brandDetail(authToken, brandId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand/${brandId}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  // ----------Brand info Api-----------
  static async addBrand(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/superAdmin/brand`, data, "post", header);
    return res;
  }

  /* ------Delete brand  API -----*/
  static async deleteBrand(authToken, brandId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/brand/${brandId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // ----------Member List Api-----------
  static async memberList(authToken, pageNo, search, sort) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/superAdmin/member?pageNo=${pageNo}&search=${search}&orderBy=${sort}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  //=== Get Member Detail======
  static async memberDetail(authToken, memberId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/member/${memberId}`,
      {},
      "get",
      header
    );

    return res;
  }

  //Delete Member
  static async DeleteMember(authToken, memberId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/member/${memberId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Add Member======
  static async AddMember(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/superAdmin/member", data, "post", header);

    return res;
  }

  //Height/Width/Distance meta data
  static async HeightWidthDistanceMeta(param) {
    let res = await this.request(`/superAdmin/${param}`, {}, "get", {});
    return res;
  }

  // PB Distance meta api
  static async PBdistanceMeta() {
    let res = await this.request(
      `/superAdmin/personalBestDistanceMeta`,
      {},
      "get",
      {}
    );
    return res;
  }

  // Add Event======
  static async AddEvent(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/superAdmin/event", data, "post", header);

    return res;
  }

  // Get Event Detail======
  static async GetEventDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/event/${id}`, {}, "get", header);

    return res;
  }

  // Change Event Status======
  static async ChangeEventStatus(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${id}/activeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  //DeleteGroup
  static async DeleteEvent(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Send event notification
  static async SendEventNotification(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${id}/sendNotification`,
      data,
      "post",
      header
    );
    return res;
  }

  // Get Event======
  static async GetEvent(authToken, page, search, order) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event?pageNo=${page}&search=${search}&orderBy=${order}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Event======
  static async GetEventByDate(authToken, page, date) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event?pageNo=${page}&eventDate=${date}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Workout  Logbook======
  static async getWorkoutLogbook(authToken, id, date) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/member/${id}/logbook?date=${date}`,
      {},
      "get",
      header
    );
    return res;
  }

  //Post Image Gallery======
  static async PostImageGallery(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${id}/gallery`,
      data,
      "post",
      header
    );
    return res;
  }

  // Add workout logbook======
  static async AddWorkoutLogbook(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/member/${id}/logbook`,
      data,
      "post",
      header
    );
    return res;
  }
  //Post Image Gallery======
  static async UpdateImageGallery(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${id}/gallery`,
      data,
      "put",
      header
    );
    return res;
  }

  //Delete logbook workout======
  static async DeleteLogbook(authToken, memberId, logbookId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/member/${memberId}/logbook/${logbookId}`,
      {},
      "delete",
      header
    );

    return res;
  }
  //Post Image Gallery======
  static async DeleteGalleryImage(authToken, eventId, ImageId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${eventId}/gallery/${ImageId}`,
      {},
      "delete",
      header
    );

    return res;
  }

  // Get logbook workout type List======
  static async getLogbookWorkoutType(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/workoutType`, {}, "get", header);
    return res;
  }

  // Get workoutType List======
  static async getWorkoutType(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/workoutTypeList`,
      {},
      "get",
      header
    );
    return res;
  }

  //Add Poll======
  static async AddPOll(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${id}/poll`,
      data,
      "post",
      header
    );
    return res;
  }

  // save Workout type======
  static async saveWorkoutType(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/workoutType`,
      data,
      "post",
      header
    );
    return res;
  }

  static async GetEventVoterList(authToken, skip, eventId, answerId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${eventId}/pollVoters?answerId=${answerId}&skipPaging=${skip}`,
      {},
      "get",
      header
    );
    return res;
  }

  //Delete workout type
  static async DeleteWorkoutType(authToken, workoutTypeId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/workoutType/${workoutTypeId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // search member of the brand======
  static async searchMemberOfBrand(authToken, page, search, brandId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/memberSearch?pageNo=${page}&search=${search}&brandId=${brandId}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Event Voter List======
  static async DeleteEventVoter(authToken, eventId, voteId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/event/${eventId}/poll/${voteId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Send invitation======
  static async SendInvitation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/memberInvite`,
      data,
      "post",
      header
    );
    return res;
  }

  // Get Event Voter List======
  static async GetDashboardData(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/dashboard`, {}, "get", header);
    return res;
  }

  // Get Policy And Condition======
  static async GetConditionAndPolicy(authToken, pageType) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/setting/page?pageType=${pageType}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Post Policy And Condition======
  static async PostPolycyAndCondition(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/setting/page`,
      data,
      "post",
      header
    );
    return res;
  }

  // Get distance List======
  static async getDistance(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/distance`, {}, "get", header);
    return res;
  }

  // save distance ======
  static async saveDistance(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/distance`, data, "post", header);
    return res;
  }

  //Delete distance
  static async DeleteDistance(authToken, distanceId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/distance/${distanceId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //===== PB DISTANCE API======

  // Get PBdistance List======
  static async getPBDistance(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/personalBestDistance`,
      {},
      "get",
      header
    );
    return res;
  }

  // save PBdistance ======
  static async savePBDistance(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/personalBestDistance`,
      data,
      "post",
      header
    );
    return res;
  }

  //Delete PBdistance
  static async DeletePBDistance(authToken, personalBestId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/personalBestDistance/${personalBestId}`,
      {},
      "delete",
      header
    );
    return res;
  }
}

export default SublyApi;
