import { Navigate, Route, Routes } from "react-router-dom";
import CommonLayout from "../Common/CommonLayout/CommonLayout";
import Login from "../AuthComponents/Login/Login";
import DashBoards from "../AppComponents/DashBoard/DashBoard";
import Brands from "../AppComponents/Brands/Brands";
import BrandInfo from "../AppComponents/Brands/BrandInfo";
import MembersPage from "../AppComponents/Members/MembersPage";
import EventPage from "../AppComponents/Events/EventPage";
import MemberDetail from "../AppComponents/Members/MembersDetail";
import MemberDetailEdit from "../AppComponents/Members/MemberDetailEdit";
import DistancePage from "../AppComponents/Distance/DistancePage";
import WorkoutPage from "../AppComponents/WorkoutTypes/WorkoutPage";
import AddEvent from "../AppComponents/Events/AddEvent";
import ScrollToTop from "../Helpers/ScrollToTop";
import PageNotFound from "../Common/PageNotFound/PageNotFound";
import { useSelector } from "react-redux";
import AddBrand from "../AppComponents/Brands/AddBrand";
import BrandTab from "../AppComponents/Brands/BrandTab";
import MemberDetailTab from "../AppComponents/Members/MemberDetailTab";
import SaveChangesModal from "../AppComponents/Brands/SaveChangesModal";
import React from "react";
import AddMember from "../AppComponents/Members/AddMember";
import EventDetailLayout from "../AppComponents/Events/EventDetail/EventDetailLayout/EventDetailLayout";
import AddWorkoutType from "../AppComponents/WorkoutTypes/AddWorkoutTypes";
import TermsCondition from "../AppComponents/TermsCondition/TermsCondition";
import Policy from "../AppComponents/Policy/Policy";
import ContactUs from "../AppComponents/ContactUs/ContactUs";

export default function Routers() {
  const isAuthenticated = useSelector((state) => state.user.userToken);

  return (
    <>
      <React.Fragment>
        {/* <ScrollToTop /> */}
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <CommonLayout />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          >
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<DashBoards />} />
            <Route path="brands" element={<Brands />} />
            {/* <Route path="/brands/brand-info" element=<BrandInfo />} /> */}

            <Route path="brands/add-brands" element={<AddBrand />} />

            <Route path="brands/brand-info" element={<BrandTab />} />
            <Route path="members" element={<MembersPage />} />

            <Route
              path="members/member-details"
              element={<MemberDetailTab />}
            />
            <Route
              path="members/member-details-edit"
              element={<MemberDetailEdit />}
            />

            <Route path="members/member-add" element={<AddMember />} />
            <Route path="events" element={<EventPage />} />
            <Route path="events/event-detail" element={<EventDetailLayout />} />
            <Route path="add-events" element={<AddEvent />} />
            <Route path="distance" element={<DistancePage />} />
            <Route path="workout-page" element={<WorkoutPage />} />

            <Route
              path="workout-page/add-workoutTypes"
              element={<AddWorkoutType />}
            />

            <Route path="terms-condition" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<Policy />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>

          <Route path="*" element={<Navigate to="/dashboard" />} />
          {!isAuthenticated && <Route path="/login" element={<Login />} />}
        </Routes>
        <SaveChangesModal />
      </React.Fragment>
    </>
  );
}
